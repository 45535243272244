import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  weights,
  weightsForm,
  goals,
  goalsForm,
  challenges,
  challengesForm
} from "./Reducers";

export default combineReducers({
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  weights,
  weightsForm,
  goals,
  goalsForm,
  challenges,
  challengesForm,
  currentTheme,
});

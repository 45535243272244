import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_GOAL,
    DELETE_GOAL,
    GOAL_FORM_TOOGLE_LOADING,
    SET_GOAL_LIST,
    SHOW_NOTIFICATION,
    UPDATE_GOAL,
} from "../constants";
import { formatGoals } from "./settingsActionsUtils";

/* GOAL LIST */
export const fetchGoals = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.goals.list)
            .then((response) => {
                const goals = formatGoals(response.data);
                dispatch({ type: SET_GOAL_LIST, payload: keyBy(goals, "_id") });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// GOALS
export const createGoal = (newGoal) => {
    return async (dispatch) => {
        dispatch({ type: GOAL_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.goals.create, newGoal)
            .then((response) => {
                const { _id } = response.data;
                const goal = formatGoals({ ...newGoal, _id });
                dispatch({ type: ADD_NEW_GOAL, payload: goal });
                dispatch({ type: GOAL_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Meta creada con éxito.",
                    },
                });
                return response.status;
            })
            .catch((err) => {
                dispatch({ type: GOAL_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateGoal = (updatedGoal) => {
    return async (dispatch) => {
        dispatch({ type: GOAL_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.goals.edit}${updatedGoal._id}`, updatedGoal)
            .then((response) => {
                const goal = formatGoals(response.data);
                dispatch({ type: UPDATE_GOAL, payload: goal });
                dispatch({ type: GOAL_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Meta actualizada.",
                    },
                });
                return response.status;
            })
            .catch((err) => {
                dispatch({ type: GOAL_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteGoals = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: GOAL_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.goals.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_GOAL, payload: Ids });
                dispatch({ type: GOAL_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Meta eliminada.",
                    },
                });
                return response.status;
            })
            .catch((err) => {
                dispatch({ type: GOAL_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

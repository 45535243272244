import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_WEIGHT,
    DELETE_WEIGHT,
    WEIGHT_FORM_TOOGLE_LOADING,
    SET_WEIGHT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_WEIGHT,
} from "../constants";
import { formatWeights } from "./settingsActionsUtils";

/* WEIGHT LIST */
export const fetchWeights = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.weights.list)
            .then((response) => {
                const weights = formatWeights(response.data);
                dispatch({ type: SET_WEIGHT_LIST, payload: keyBy(weights, "_id") });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// WEIGHTS
export const createWeight = (newWeight) => {
    return async (dispatch) => {
        dispatch({ type: WEIGHT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.weights.create, newWeight)
            .then((response) => {
                const { _id } = response.data;
                const weight = formatWeights({ ...newWeight, _id });
                dispatch({ type: ADD_NEW_WEIGHT, payload: weight });
                dispatch({ type: WEIGHT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Peso creado con éxito.",
                    },
                });
                return response.status;
            })
            .catch((err) => {
                dispatch({ type: WEIGHT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateWeight = (updatedWeight) => {
    return async (dispatch) => {
        dispatch({ type: WEIGHT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.weights.edit}${updatedWeight._id}`, updatedWeight)
            .then((response) => {
                const weight = formatWeights(response.data);
                dispatch({ type: UPDATE_WEIGHT, payload: weight });
                dispatch({ type: WEIGHT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Peso actualizado.",
                    },
                });
                return response.status;
            })
            .catch((err) => {
                dispatch({ type: WEIGHT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteWeights = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: WEIGHT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.weights.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_WEIGHT, payload: Ids });
                dispatch({ type: WEIGHT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Peso eliminado.",
                    },
                });
                return response.status;
            })
            .catch((err) => {
                dispatch({ type: WEIGHT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CHALLENGE,
    DELETE_CHALLENGE,
    CHALLENGE_FORM_TOOGLE_LOADING,
    SET_CHALLENGE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_CHALLENGE,
} from "../constants";
import { formatChallenges } from "./settingsActionsUtils";

/* CHALLENGE LIST */
export const fetchChallenges = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.challenges.list)
            .then((response) => {
                const challenges = formatChallenges(response.data);
                dispatch({ type: SET_CHALLENGE_LIST, payload: keyBy(challenges, "_id") });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// CHALLENGES
export const createChallenge = (newChallenge) => {
    return async (dispatch) => {
        dispatch({ type: CHALLENGE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.challenges.create, newChallenge)
            .then((response) => {
                const { _id } = response.data;
                const challenge = formatChallenges({ ...newChallenge, _id });
                dispatch({ type: ADD_NEW_CHALLENGE, payload: challenge });
                dispatch({ type: CHALLENGE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Reto creado con éxito.",
                    },
                });
                return response.status;
            })
            .catch((err) => {
                dispatch({ type: CHALLENGE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateChallenge = (updatedChallenge) => {
    return async (dispatch) => {
        dispatch({ type: CHALLENGE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.challenges.edit}${updatedChallenge._id}`, updatedChallenge)
            .then((response) => {
                const challenge = formatChallenges(response.data);
                dispatch({ type: UPDATE_CHALLENGE, payload: challenge });
                dispatch({ type: CHALLENGE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Reto actualizado.",
                    },
                });
                return response.status;
            })
            .catch((err) => {
                dispatch({ type: CHALLENGE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteChallenges = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CHALLENGE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.challenges.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CHALLENGE, payload: Ids });
                dispatch({ type: CHALLENGE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Reto eliminado.",
                    },
                });
                return response.status;
            })
            .catch((err) => {
                dispatch({ type: CHALLENGE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
